<template>
  <div>
    <portal to="settings">
      <tiles :schema="tilesSchema" />
    </portal>
    <div class="q-pa-sm border-bottom row items-center text-grey-7 text-weight-bold q-mb-xs">
      <h6 class="q-ma-none text-subtitle2 text-weight-bold">
        {{ $t('Allocation queues') }}
      </h6>
      </div>
    <q-table
        :style="`height: ${terminalContainerHeight - 50}px;`"
        row-key="id"
        :rows="items"
        :columns="columns"
        v-model:pagination="pagination"
        :loading="$service.acceptance.isLoading"
        :filter="filter"
        :rows-per-page-options="[]"
        table-header-class="d-none"
        virtual-scroll
        binary-state-sort
        flat
        @request="onRequest"
    >
      <template v-slot:loading>
        <q-inner-loading
            showing
            color="primary"
        />
      </template>

      <template v-slot:body="props">
        <q-tr
            :props="props"
            class="clickable"
            @click="handleClick(props.row)"
        >
          <q-td
              key="details"
              :props="props"
              style="max-width: 100vw; padding: 0 6px 6px !important;"
              class="no-border"
          >
            <div class="border border--radius-sm rounded q-py-xs q-px-sm">
              <div
                  class="text-h6 text-center text-capitalize text-weight-bold"
                  style="white-space: normal !important;"
              >
                {{ props.row.name }}
              </div>

              <div class="text-caption text-weight-bold text-center">
                {{ $t('ID') + ': ' + props.row.id }}
              </div>

            </div>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RuledAllocation',
  data () {
    return {
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: '',
          name: 'details',
          align: 'left'
        }
      ],
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        complete: 'success',
        checking: 'warning',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark'
      },
      typeLabels: {
        products: 'Products',
        items: 'Items',
        palletized: 'Palletized',
        batch: 'Batch & EXP'
      },
      typeColors: {
        products: 'blue-3',
        items: 'deep-purple-3',
        batch: 'green-2',
        palletized: 'teal-11'
      },
      helper: null,
      items: [],
      newItems: 0,
      checkingItems: 0,
    }
  },
  computed: {
    tilesSchema () {
      return []
    },
    ...mapGetters([
      'terminalContainerHeight',
    ]),
  },
  mounted () {
    const data = {
      message: 'Choose queue',
      disabledNotifications: true
    }
    this.addHelper(data)
        .then(helper => {
          this.helper = helper
        })

    this.refresh()
        .then(() => {
          this.loadDocuments()
        })
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }
  },
  methods: {
    ...mapMutations([
      'removeHelper'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    loadDocuments () {
      const query = {
        page: 1,
        per_page: 5,
        filter: [
          {type: 'eq', field: 'adapter', value: 'ruled_allocation'}
        ]
      }

      return this.$service.picking.getAll(query)
    },
    onRequest (data = {}) {
      this.pagination = data.pagination || {}
      let query = this.$utils.buildQuery(this.pagination)

      query = {
        filter: [
          {type: 'eq', field: 'adapter', value: 'ruled_allocation'}
        ]
      }

      return this.$service.picking.getAll(query)
          .then((data) => {

            this.items = data.items
            return data
          })
    },
    refresh () {
      const pagination = {
        per_page: 25,
        page: 1,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ]
      }

      return this.onRequest({ pagination })
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    handleClick (item) {
      this.$router.push('/terminal/ruled-allocation/' + item.id)
    },
  }
}
</script>

<style>
.table--border .q-table tbody .q-tr td {
  border-bottom-width: 2px;
}
</style>


